import Alternative from "views/pages/dashboards/Alternative.js";
import Buttons from "views/pages/components/Buttons.js";
import Calendar from "views/pages/Calendar.js";
import Cards from "views/pages/components/Cards.js";
import Charts from "views/pages/Charts.js";
import Components from "views/pages/forms/Components.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Elements from "views/pages/forms/Elements.js";
import Google from "views/pages/maps/Google.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
import Lock from "views/pages/pages/Lock.js";
import Login from "views/pages/pages/Login.js";
import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/pages/Pricing.js";
import Profile from "views/pages/pages/Profile.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import Register from "views/pages/pages/Register.js";
import RTLSupport from "views/pages/pages/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import Timeline from "views/pages/pages/Timeline.js";
import Typography from "views/pages/components/Typography.js";
import Validation from "views/pages/forms/Validation.js";
import Vector from "views/pages/maps/Vector.js";
import Widgets from "views/pages/Widgets.js";

import Nurseries from "views/pages/pages/nurseries.js";
import Payments from "views/pages/pages/payments.js";
import Subscription from "views/pages/pages/subscription.js";

const routes = [
  {
      path: "/dashboard",
      name: "Dashboard",
      icon: "ni ni-shop text-primary",
      miniName: "D",
      component: Dashboard,
      layout: "/admin"
  },
  {
      path: "/nurseries",
      name: "Nurseries",
      icon: "ni ni-shop text-primary",
      miniName: "N",
      component: Nurseries,
      layout: "/admin"
  },
  {
      path: "/payments",
      name: "Paymetns",
      icon: "ni ni-shop text-primary",
      miniName: "P",
      component: Payments,
      layout: "/admin"
  },
  {
      path: "/subscription",
      name: "Subscription",
      icon: "ni ni-shop text-primary",
      miniName: "S",
      component: Subscription,
      layout: "/admin"
  }
];

export default routes;
