import React from "react";

import {
	Button,
	Card,
	CardHeader,
	CardBody,
	Container,
	Form,
	UncontrolledTooltip,
	Table,
	FormGroup,
	Input,
	Row,
	Col,
	Label,
} from "reactstrap";
const subscriptionTable = [
	{
		id: 1,
		nurseryName: "Grandmas Nursery",
		startDate: "2020-10-13",
		renewalDate: "2020-10-13",
		lastPaid: "2020-10-13",
		paidAmount: "£30.00",
		status: "Paid",
	},
	{
		id: 2,
		nurseryName: "Test Nursery",
		startDate: "2022-11-19",
		renewalDate: "2021-12-18",
		lastPaid: "2020-11-15",
		paidAmount: "£35.00",
		status: "Pending",
	},
	{
		id: 3,
		nurseryName: "Test2 Nursery",
		startDate: "2019-11-12",
		renewalDate: "2022-11-03",
		lastPaid: "2021-11-03",
		paidAmount: "£20.00",
		status: "Paid",
	},
];
function Subscription() {
	return (
		<>
			<Container className="mt--0" fluid>
				<Row>
					<Col className="order-xl-1" xl="12">
						<Card  className="px-3">
							<CardHeader>
								<Row className="align-items-center">
									<Col xs="8">
										<h3 className="mb-0">Subscription</h3>
									</Col>
									<Col className="text-right" xs="4"></Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div className="pl-lg-4">
									<div>
										<Row>
											<Col lg="12">
												<div className="table-responsive">
													<Table className="align-items-center table-flush">
														<thead className="thead-light">
															<tr>
																<th className="sort" scope="col">
																	Id
																</th>
																<th className="sort" scope="col">
																	Nursery Name
																</th>
																<th className="sort" scope="col">
																	Parent Name
																</th>
																<th className="sort" scope="col">
																	Paid On
																</th>
																<th className="sort" scope="col">
																	Paid To
																</th>
																<th className="sort" scope="col">
																	Amount
																</th>
																<th className="sort" scope="col">
																	Status
																</th>
															</tr>
														</thead>
														<tbody className="list">
															{subscriptionTable.map((data) => (
																<tr key={data.id}>
																	<td className="text-muted">
																		{data.id ?? ""}
																	</td>
																	<td className="text-muted">
																		{data.nurseryName ?? ""}
																	</td>
																	<td className="text-muted">
																		{data.startDate ?? ""}
																	</td>
																	<td className="text-muted">
																		{data.renewalDate ?? ""}
																	</td>
																	<td className="text-muted">
																		{data.lastPaid ?? ""}
																	</td>
																	<td className="text-muted">
																		{data.paidAmount ?? ""}
																	</td>
																	<td className="text-muted">
																		{data.status ?? ""}
																	</td>
																</tr>
															))}
															{subscriptionTable.length < 1 && (
																<tr>
																	<td
																		className="text-center text-danger"
																		colSpan={6}
																	>
																		No Data Found
																	</td>
																</tr>
															)}
														</tbody>
													</Table>
												</div>
											</Col>
										</Row>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Subscription;
