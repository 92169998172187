import { React, useState, useEffect } from "react";

import {
	Button,
	Card,
	CardHeader,
	CardBody,
	Container,
	Form,
	UncontrolledTooltip,
	Table,
	FormGroup,
	Input,
	Row,
	Col,
	Label,
} from "reactstrap";
const NurseryTable = [
	{
		id: 1,
		name: "Grandmas Nursery",
		email: "jones@gmail.com",
		telepone: 6479864,
	},
	{
		id: 2,
		name: "Kids Nursery",
		email: "nursery1@kids-in.co.uk",
		telepone: 1579864,
	},
	{ id: 3, name: "test Nursery", email: "test1@gmail.com", telepone: 6479864 },
	{
		id: 4,
		name: "Test 1",
		email: "arbaz.mateen@knarkz.co.uk",
		telepone: 54684864,
	},
];
function Nurseries() {
	useEffect(() => {
		console.log(dataToView);
	}, []);

	const [nurseryTableVisible, setNurseryTableVisible] = useState(true);
	const [nurseryViewVisible, setNurseryViewVisible] = useState(false);
	const [dataToView, setDataToView] = useState([]);
	const dataViewHandler = (e) => {
		setNurseryTableVisible(false);
		setNurseryViewVisible(true);
		var viewData = NurseryTable.filter((item) => item.id === e.target.id);
		setDataToView([viewData]);
	};
	const backButtonHandler = () => {
		setNurseryTableVisible(true);
		setNurseryViewVisible(false);
	};

	return (
		<>
			<Container className="mt--0" fluid>
				<Row>
					<Col className="order-xl-1" xl="12">
						<Card  className="px-3">
							<CardHeader>
								<Row className="align-items-center">
									<Col xs="8">
										<h3 className="mb-0">Nurseries</h3>
									</Col>
									<Col className="text-right" xs="4"></Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div className="pl-lg-4">
									{nurseryTableVisible && (
										<div>
											<Row>
												<Col lg="12">
													<div className="table-responsive">
														<Table className="align-items-center table-flush">
															<thead className="thead-light">
																<tr>
																	<th className="sort" scope="col">
																		Id
																	</th>
																	<th className="sort" scope="col">
																		Name
																	</th>
																	<th className="sort" scope="col">
																		Email
																	</th>
																	<th className="sort" scope="col">
																		Telephone
																	</th>
																	<th className="sort" scope="col">
																		Action
																	</th>
																</tr>
															</thead>
															<tbody className="list">
																{NurseryTable.map((data) => (
																	<tr key={data.id}>
																		<td className="text-muted">
																			{data.id ?? ""}
																		</td>
																		<td className="text-muted">
																			{data.name ?? ""}
																		</td>
																		<td className="text-muted">
																			{data.email ?? ""}
																		</td>
																		<td className="text-muted">
																			{data.telepone ?? ""}
																		</td>
																		<td>
																			<Button
																				color="secondary"
																				size="sm"
																				id={data.id}
																				onClick={dataViewHandler}
																			>
																				View
																			</Button>
																		</td>
																	</tr>
																))}
																{NurseryTable.length < 1 && (
																	<tr>
																		<td
																			className="text-center text-danger"
																			colSpan={5}
																		>
																			No Data Found
																		</td>
																	</tr>
																)}
															</tbody>
														</Table>
													</div>
												</Col>
											</Row>
										</div>
									)}
									{nurseryViewVisible && (
										<div>
											<Row>
												<Col lg="12">
													<Table
														className="align-items-center table-flush"
														responsive
													>
														<thead className="thead-light">
															<tr>
																<th colSpan={2}>NURSERY NAME</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td colSpan={2} className="table-user">
																	<h5>Eversleigh Nursery</h5>
																</td>
															</tr>
														</tbody>
														<thead className="thead-light">
															<tr>
																<th colSpan={2}>ADDRESS</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td colSpan={2} className="table-user">
																	<h5>Leeds</h5>
																</td>
															</tr>
														</tbody>
														<thead className="thead-light">
															<tr>
																<th colSpan={2}>CONTACT INFORMATION</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className="table-user w-25">
																	<h5>Main Telephone:</h5>
																	<h5 className="mt-2">
																		Alternative Telephone:
																	</h5>
																</td>
																<td className="table-user">
																	<h5>03225644856</h5>
																	<h5 className="mt-2">0615478455</h5>
																</td>
															</tr>
														</tbody>
														<thead className="thead-light">
															<tr>
																<th colSpan={2}>EMAIL</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td colSpan={2} className="table-user">
																	<h5>jones@gmail.com</h5>
																</td>
															</tr>
														</tbody>
													</Table>
												</Col>
											</Row>
											<Row>
												<Col lg="12 text-right">
													<Button
														color="primary"
														size="sm"
														onClick={backButtonHandler}
													>
														BACK
													</Button>
												</Col>
											</Row>
										</div>
									)}
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Nurseries;
